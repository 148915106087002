import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <Layout title="404: Not found">
      <section className={classes.banner}>
        <Container className={classes.bannerContent}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h1
                style={{
                  color: "#fe5f55",
                  fontFamily: "Montserrat",
                  fontDisplay: "swap",
                }}
              >
                Pagina non trovata
              </h1>
              <p style={{ color: "#fff" }}>
                Che tristezza, la pagina che stai cercando non è stata
                trovata...
              </p>
              <Button href="/" variant="contained" color="primary">
                Torna alla homepage
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
